/*
    Example fishbone styling... note that you can't actually change
    line markers here, which is annoying
*/

html,
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

/* get it? gill? */
* {
  font-family: "Gill Sans", "Gill Sans MT";
}

.label-0 {
  font-size: 1.5em;
}
.label-1 {
  font-size: 1.5em;
  fill: #111;
}
.label-2 {
  font-size: 1em;
  fill: #444;
}
.label-3 {
  font-size: 0.9em;
  fill: #888;
}
.label-4 {
  font-size: 0.8em;
  fill: #aaa;
}

.link-0 {
  stroke: #000;
  stroke-width: 2px;
}
.link-1 {
  stroke: #333;
  stroke-width: 1px;
}
.link-2,
.link-3,
.link-4 {
  stroke: #666;
  stroke-width: 0.5px;
}
